<template lang="">
  <div>
    <v-card :elevation="1" class="v-sheet theme--light br-0">
      <div class="row mt-0 mb-4">
        <div
          class="col-12 col-md-12 m-0 p-0"
          style="margin-top: -5px !important;"
        >
          <DivisorColor :ptl="`3px`" :ptr="`3px`" :pbl="`3px`" :pbr="`3px`" />
        </div>
      </div>
      <v-card-title>
        <v-row class="col-md-12">
          <v-col cols="12">
            <v-btn
              class="float-right"
              outlined
              color="black"
              :to="
                currentRoles.includes('SCO$ADMINISTRADOR') ||
                currentRoles.includes('SCO$SUBADMINISTRADOR')
                  ? `/dashboard-administrador`
                  : ``
              "
            >
              <v-icon>mdi-arrow-left</v-icon>
              Regresar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="12" md="8">
            <span class="card-label font-weight-bolder text-dark">
              Estimación anual del presupuesto </span
            ><br />
            <v-skeleton-loader
              v-if="skeletonLoading"
              type="list-item-two-line"
            ></v-skeleton-loader>
            <div v-if="!skeletonLoading">
              <span class="text-muted mt-3 font-weight-bold font-size-md">
                Administración
              </span>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="4">
           <!---<v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              @click="modalNuevo"
              :disabled="skeletonLoading || accionesBloqueadas"
            >
              Consolidados
            </v-btn>-->

            <v-btn
              color="light-blue-502"
              dark
              class="white--text mb-2 float-right"
              to="/proyectos/cns/estimacion-presupuesto/consolidado-general"
            >
              Consolidados
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="col-md-12 m-0 p-0">
          <v-divider></v-divider>
        </v-row>
      </v-card-title>
      <v-card-text class="pl-0 pr-0">
        <v-data-table
          class="elevation-0"
          :headers="headers"
          :items="entidades"
          :search="filtro"
          :loading="tableLoading"
          no-data-text="No se encontraron registros"
          loading-text="Cargando..."
          :header-props="{
            sortByText: 'Ordenar por'
          }"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-page-first',
            lastIcon: 'mdi-page-last',
            prevIcon: 'mdi-chevron-left',
            nextIcon: 'mdi-chevron-right',
            'items-per-page-text': 'Registros por página',
            pageText: '{0}-{1} de {2}'
          }"
        >
          <template v-slot:top>
            <!-- v-container, v-col and v-row are just for decoration purposes. -->
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="12" sm="12" xs="12">
                  <v-text-field
                    autocomplete="off"
                    class=""
                    dense
                    filled
                    color="blue-grey lighten-2"
                    v-model="filtro"
                    label="Id / Nombre de la entidad"
                    maxlength="200"
                  >
                    <v-icon slot="append" color="blue-grey darken-2"
                      >mdi-magnify</v-icon
                    >
                  </v-text-field>
                </v-col>

                <!-- <v-col cols="6" md="6" sm="6" xs="6">
                  <v-select
                    v-model="estadoProyecto"
                    :items="estadosProyecto"
                    :loading="false"
                    dense
                    filled
                    label="Estado"
                    :no-data-text="'No existen estados registrados'"
                    menu-props="auto"
                    return-object
                  ></v-select>
                </v-col> -->

                <v-col cols="2" md="2" sm="12" xs="6" class="d-none">
                  <v-btn
                    class="ma-0 white--text d-none"
                    medium
                    color="light-blue darken-2"
                  >
                    <v-icon left>mdi-magnify</v-icon> Buscar
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.id }}</td>
              <td>
                {{ item.nombreInstitucion }}
              </td>
              <td>
                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  large
                  depressed
                  :to="`/proyectos/cns/estimacion-presupuesto/1/${item.id}`"
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye-outline</v-icon>
                  Estimaciones<br> con código Sicoopera
                </v-btn>

                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  large
                  depressed
                  :to="`/proyectos/cns/estimacion-presupuesto/2/${item.id}`"
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-eye-outline</v-icon>
                  Estimaciones<br> sin código Sicoopera
                </v-btn>

                <v-btn
                  class="ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize"
                  large
                  depressed
                  :to="`/proyectos/cns/consolidado-estimacion-presupuesto/${item.id}`"
                  color="blue-grey lighten-5"
                >
                  <v-icon left>mdi-folder</v-icon>
                  Consolidados
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
//import DownloadFile from "@/core/untils/downloadfile.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {OBTENER_ITEMS_CATALOGO} from "@/core/services/store/catalogos/catalogointerno.module";
export default {
  data() {
    return {
      skeletonLoading: false,
      entidades: [],
      tableLoading: false,
      filtro: "",
      estadoProyecto: "",
      estadosProyecto: []
    }
  },
  methods: {
    //Obtener las instituciones
    async obtenerInstituciones() {
      this.entidades = [];
      this.tableLoading = false;

      this.instituciones = [];
      await this.$store
        .dispatch(OBTENER_ITEMS_CATALOGO, { endpoint: `Entidades/all/1` })
        .then(res => {
          if (res.status == 200) {
            this.entidades = res.data;
          }
          this.tableLoading = false;
        })
        .catch(() => {
          this.tableLoading = false;
          this.entidades = [];
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Inicio", route: "dashboard" },
      { title: "Proyectos CNS" },
      { title: "Estimación Anual" }
    ]);

    // var ciphertext = this.$CryptoJS.AES.encrypt("2", 'KEYADMINCNS2022');
  },
  created() {
    this.obtenerInstituciones();
  },
  computed: {
    ...mapGetters({ currentUser: "currentUser" }), //, currentRoles :"currentRoles"

    currentRoles: {
      get() {
        return this.$store.state.auth.roles;
      }
    },
    headers() {
      return [
        {
          text: "Id",
          align: "start",
          sortable: true,
          value: "id"
        },
        {
          text: "Nombre de la Entidad",
          align: "start",
          sortable: true,
          value: "nombreInstitucion"
        },
        {
          text: "Acciones",
          align: "start",
          sortable: false,
          value: ""
        }
      ];
    }
  }
};
</script>
<style lang=""></style>
