var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"v-sheet theme--light br-0",attrs:{"elevation":1}},[_c('div',{staticClass:"row mt-0 mb-4"},[_c('div',{staticClass:"col-12 col-md-12 m-0 p-0",staticStyle:{"margin-top":"-5px !important"}},[_c('DivisorColor',{attrs:{"ptl":"3px","ptr":"3px","pbl":"3px","pbr":"3px"}})],1)]),_c('v-card-title',[_c('v-row',{staticClass:"col-md-12"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"float-right",attrs:{"outlined":"","color":"black","to":_vm.currentRoles.includes('SCO$ADMINISTRADOR') ||
              _vm.currentRoles.includes('SCO$SUBADMINISTRADOR')
                ? "/dashboard-administrador"
                : ""}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Regresar ")],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"8"}},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Estimación anual del presupuesto ")]),_c('br'),(_vm.skeletonLoading)?_c('v-skeleton-loader',{attrs:{"type":"list-item-two-line"}}):_vm._e(),(!_vm.skeletonLoading)?_c('div',[_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-md"},[_vm._v(" Administración ")])]):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-btn',{staticClass:"white--text mb-2 float-right",attrs:{"color":"light-blue-502","dark":"","to":"/proyectos/cns/estimacion-presupuesto/consolidado-general"}},[_vm._v(" Consolidados ")])],1)],1),_c('v-row',{staticClass:"col-md-12 m-0 p-0"},[_c('v-divider')],1)],1),_c('v-card-text',{staticClass:"pl-0 pr-0"},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.entidades,"search":_vm.filtro,"loading":_vm.tableLoading,"no-data-text":"No se encontraron registros","loading-text":"Cargando...","header-props":{
          sortByText: 'Ordenar por'
        },"footer-props":{
          showFirstLastPage: true,
          firstIcon: 'mdi-page-first',
          lastIcon: 'mdi-page-last',
          prevIcon: 'mdi-chevron-left',
          nextIcon: 'mdi-chevron-right',
          'items-per-page-text': 'Registros por página',
          pageText: '{0}-{1} de {2}'
        }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12","xs":"12"}},[_c('v-text-field',{attrs:{"autocomplete":"off","dense":"","filled":"","color":"blue-grey lighten-2","label":"Id / Nombre de la entidad","maxlength":"200"},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}},[_c('v-icon',{attrs:{"slot":"append","color":"blue-grey darken-2"},slot:"append"},[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{staticClass:"d-none",attrs:{"cols":"2","md":"2","sm":"12","xs":"6"}},[_c('v-btn',{staticClass:"ma-0 white--text d-none",attrs:{"medium":"","color":"light-blue darken-2"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-magnify")]),_vm._v(" Buscar ")],1)],1)],1)],1)]},proxy:true},{key:"item",fn:function(ref){
        var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(_vm._s(item.id))]),_c('td',[_vm._v(" "+_vm._s(item.nombreInstitucion)+" ")]),_c('td',[_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"large":"","depressed":"","to":("/proyectos/cns/estimacion-presupuesto/1/" + (item.id)),"color":"blue-grey lighten-5"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" Estimaciones"),_c('br'),_vm._v(" con código Sicoopera ")],1),_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"large":"","depressed":"","to":("/proyectos/cns/estimacion-presupuesto/2/" + (item.id)),"color":"blue-grey lighten-5"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-eye-outline")]),_vm._v(" Estimaciones"),_c('br'),_vm._v(" sin código Sicoopera ")],1),_c('v-btn',{staticClass:"ma-2 btn-bg-light blue-grey--text lighten-2--text font-weight-medium text-capitalize",attrs:{"large":"","depressed":"","to":("/proyectos/cns/consolidado-estimacion-presupuesto/" + (item.id)),"color":"blue-grey lighten-5"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-folder")]),_vm._v(" Consolidados ")],1)],1)])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }